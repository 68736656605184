import { mapActions, mapState } from 'vuex'
import  { myMixin } from '../../../mixins';

export default{
  mixins: [myMixin],
  data: () => ({
    collections: [],
    params: {},
    message: '',
    error: false,
    errors: {},
    loading: false,
    loadingRefresh: false,
    meta: {},
    itemDelete: {},
    modelItems: {},
    model: {},
    show: false,
    showForm: false,
    loadingProgress: false,
    selectedItem: null,
    showMessageGenerate: false,
    textDelete: '',
    deleting: false,
    showDowload: false,
    showProcessPayment: false,
    showCancelPayment: false,
    itemProcessPayment: {},
    showItems: false,
    textProgress: 'Carregando dados...',
    colorProgress: 'primary',
    headers: [
        { text: 'ID', value: 'id', sortable: false},
        { text: 'Tipo', value: 'type', sortable: false},
        { text: 'Mês', value: 'month', align: 'center', sortable: false},
        { text: 'Data', value: 'date', align: 'center', sortable: false},
        { text: 'Usuário', value: 'user.name', sortable: false, align: 'center',},
        { text: 'Status', value: 'status', align: 'center', sortable: false},
        { text: 'Ações', value: 'actions', sortable: false},
    ]
    }),

    dateTime: function (value) {
            
        if(value){
            var data = new Date(value);
            return data.toLocaleDateString() + ' ' + data.toLocaleTimeString();
        }else{
            return '';
        }
    },
    
    watch: {
      
        params(params_new, params_old){
        if(params_new != params_old) {
            this.getData()
        }
      },
       //Função para tratar quando um item for exibido para deletar
      itemDelete(item_new, item_old){
          if(item_new !== item_old && Object.keys(item_new).length > 0)
          {
              this.show = true;
              this.textDelete = "Deseja excluir o item?";
          } else {
              this.show = false
              this.textDelete = '';
          }
      }, //Função para limpar a propriedade do usuário que será excluído quando usuário clica no cancelar
      show(show_new, show_old) {
          
          if(show_new != show_old && !show_new) {
              this.itemDelete = {};
          }
      },

      model(item_new, item_old){
          
        if(item_new !== item_old && this.model.id)
          {
              this.showForm = true;
              this.errors = {};
          } else if(Object.keys(this.model).length === 0){
              this.showForm = false;
              this.errors = {};
          }           
      },

      showForm(show_new, show_old) {
          if(show_new != show_old && !show_new) {
              this.model = {};
          }
      },
      
    },
    computed: {
        ...mapState('auth', ['acesso', 'user']),
    },
    
    methods: {
      ...mapActions('paymentExport', ['ActionUpdatePaymentExport', 'ActionFindPaymentExports', 'ActionDeletePaymentExport', 'ActionGeneratePaymentExport']),
        
      getData(refresh = false){
      
        this.error = false;
        this.message = '';
        var params = this.params;
        this.loadingProgress = true;
        this.showForm = false;
        this.loadingRefresh = refresh;

        params.with = 'status,user,type,companies,teachers';
        params.column_order = 'month';
        params.direction = 'desc';
        
        this.ActionFindPaymentExports(params)
            .then((res) => {
                this.collections = res.data;
                this.meta = res.meta;
            })
            .finally(() => {
                this.loading = false;
                this.loadingProgress = false;
                this.loadingRefresh = false;
                this.showProcessPayment = false;
            });
    },

    deleteItem()
    {
        this.deleting = true;
        this.message = '';
        this.error = false;
        this.errors = {};

        this.ActionDeletePaymentExport({ id: this.itemDelete.id })
            .then((res) => {
                this.message = res.message;
                this.show = false;
                this.getData();
            })
            .catch((error) =>{
               
                this.error      = true;
                this.errors     = error.errors;

                if(error.errors && error.errors.length > 0){
                    this.message = error.errors[0];
                }else{
                    this.message = error.message;
                }
               
            })
            .finally(() => {
                this.deleting = false;
                this.show = false;
            });
    },

    showGenerateItem(item)
    {
        let ids = [1, 6, 7]
        
        if(ids.includes(item.payment_export_status_id)){
            this.generate(item);
        }else if(item.payment_export_status_id == 3){
            this.selectedItem = item;
            this.showMessageGenerate = true;
        }
        
    },

    generate(item){
        
        this.message = '';
        this.textProgress = 'Processando...';
        this.colorProgress = 'success';
        this.loadingProgress = true;
        this.showMessageGenerate = false;

        this.ActionGeneratePaymentExport({ id: item.id })
            .then((res) => {
                this.message = res.message;
                this.show = false;
                this.getData();
            })
            .catch((error) =>{
                this.message = error.message;
                this.error = true;
            })
            .finally(() => {
                this.deleting = false;
                this.loadingProgress = false;
                this.textProgress = 'Carregando dados...';
                this.colorProgress = 'primary';
            });
    },

    save(item, status)
    {
        this.error = false;
        this.message = '';
        this.loading = true;
        var old_status = item.payment_export_status_id;

        item.payment_export_status_id = status;

        this.ActionUpdatePaymentExport(item)
        .then((res) => {
            this.getData();
        })
        .catch((error) =>{
            this.error      = true;
            this.errors     = error.errors;
            item.payment_export_status_id = old_status;

        })
        .finally(() => {
            this.loading = false;
            this.showProcessPayment = false;
            this.showCancelPayment = false;
            this.showLockEdit = false;
        });
        
    },

    saveLock(item)
    {
        this.error = false;
        this.message = '';
        this.loadingProgress = true;

        item.lock_edit_work_load = !item.lock_edit_work_load;

        this.ActionUpdatePaymentExport(item)
        .then((res) => {
            this.getData();
        })
        .catch((error) =>{
            this.error      = true;
            this.errors     = error.errors;
        })
        .finally(() => {
            
        });
        
    },

    showProcessDialog(item, status){
        
        this.itemProcessPayment = item;

        switch (status) {
            case 4:
                this.showCancelPayment = true;
                break;
            case 5:
                this.showProcessPayment = true;
                break;
           
        }
    },

    showItemsExport(item){
        this.modelItems = item;
        this.showItems = true;
    },

    getParams(item){

        return {
            payment_export_id: item.id,
            description: item.type.name + ' | Mês: ' + item.month,
        };

    },

  },

}
